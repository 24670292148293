import ImmutableTransformer from '@utils/ImmutableTransformer'
import { pieColors } from '../dashHelper'
import useClientTranslation from 'config/clients/useClientTranslation'

export const lineDetailedMonthAndGeoMapper = (data: any) => {
  //console.log('lineDetailedByMonthAndGeo!!!!!!!!')
  //console.log(data)
  return {
    value: data !== undefined && data.map !== undefined
      ? data.map((item: any, idx: number) => ({
        ...item,
        id: idx,
        category: item.groupName ? item.groupName : item['group_0'],
        groupName: item['group_1'] ? item['group_1'] : item.label,
        value: item.value,
      }))
      : []
  }
}

export const widgetsLine: CommonWidget = {
  /**************************************************************************
   *                                LINES
   ***************************************************************************/

  alertsLineTrendByMonthAndGeo: {
    title: 'Tendencia % EESS',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'alerts-line-trend-by-month-and-geo',
      type: 'line',
      colors: ['#269102', '#d64550'],
      radius: [40, 85],
      center: ['40%', '55%'],
      legendOrient: 'vertical',
      showHistoric: false,
      showSample: false,
      groups: [
        '% Excelente. Más de 30 encuestas',
        '% Criticas. Menos de 10 encuestas',
      ],
      wrapXAxisText: true,
      mapper: (data: any) => {
        const groupByCategory = data.reduce((all: any, item: any) => {
          // Calculos
          const excelente = item.value.answeredCount >= 30 ? 1 : 0
          const neutro =
            item.value.answeredCount >= 10 && item.value.answeredCount < 30
              ? 1
              : 0
          const critico = item.value.answeredCount < 10 ? 1 : 0

          const group = item['group_0']
          all[group] = all[group] ?? {
            excelente: 0,
            neutro: 0,
            critico: 0,
          }

          const current = {
            //...all[group],
            groupName: item.groupName,
            group: group,
            excelente: all[group].excelente + excelente,
            neutro: all[group].neutro + neutro,
            critico: all[group].critico + critico,
            total:
              all[group].excelente +
              excelente +
              all[group].neutro +
              neutro +
              all[group].critico +
              critico,
          }

          all[group] = current
          return all
        }, {})

        const result = Object.keys(groupByCategory)
          .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
          .map((key: string, idx: number) => {
            const item = groupByCategory[key]
            const groups = item.group !== undefined && item.group.split('-')
            return [
              {
                id: idx,
                group: '% Criticas. Menos de 10 encuestas',
                category:
                  item.groupName + (groups && groups[0] ? ' ' + groups[0] : ''),
                value: Math.round((100 * item.critico) / item.total),
              },
              {
                id: idx,
                group: '% Excelente. Más de 30 encuestas',
                category:
                  item.groupName + (groups && groups[0] ? ' ' + groups[0] : ''),
                value: Math.round((100 * item.excelente) / item.total),
              },
            ]
          })
          .flat(1)

        return result
      },
      indicators: [
        {
          //key: 'alert-first-response-time',
          indicator: 'survey-count',
          grouped: 'formattedMonth,geoLocation',
          keyExtract: [
            'group',
            'value',
            'groupName',
            'count',
            'group_0',
            'group_1',
          ],
          label: 'groupName',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
            featureExclude: ['groupByTopLocation'],
            periods: 10,
          },
        },
      ],
    },
  },
  alertsLineTrendByGeo: {
    title: '% EESS por Región',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'alerts-line-trend-by-geo',
      type: 'line',
      radius: [50, 100],
      center: ['40%', '55%'],
      showHistoric: false,
      showSample: false,
      legendOrient: 'vertical',
      groups: [
        '% Excelente. Más de 30 encuestas',
        '% Criticas. Menos de 10 encuestas',
      ],
      colors: ['#269102', '#d64550'],
      wrapXAxisText: true,
      mapper: (data: any) => {
        const groupByCategory = data.reduce((all: any, item: any) => {
          // Calculos
          const excelente = item.value.answeredCount >= 30 ? 1 : 0
          const neutro =
            item.value.answeredCount >= 10 && item.value.answeredCount < 30
              ? 1
              : 0
          const critico = item.value.answeredCount < 10 ? 1 : 0

          const group = item['group']
          all[group] = all[group] ?? {
            excelente: 0,
            neutro: 0,
            critico: 0,
          }

          const current = {
            //...all[group],
            group: group,
            excelente: all[group].excelente + excelente,
            neutro: all[group].neutro + neutro,
            critico: all[group].critico + critico,
            total:
              all[group].excelente +
              excelente +
              all[group].neutro +
              neutro +
              all[group].critico +
              critico,
          }

          all[group] = current
          return all
        }, {})

        const result = Object.keys(groupByCategory)
          .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
          .map((key: string) => {
            const item = groupByCategory[key]
            return [
              {
                ...item,
                group: '% Criticas. Menos de 10 encuestas',
                category: item.group,
                value: Math.round((100 * item.critico) / item.total),
              },
              {
                ...item,
                group: '% Excelente. Más de 30 encuestas',
                category: item.group,
                value: Math.round((100 * item.excelente) / item.total),
              },
            ]
          })
          .flat(1)

        return result
      },
      indicators: [
        {
          indicator: 'survey-count',
          grouped: 'geoLocation',
          keyExtract: ['group', 'value', 'groupName', 'count', 'groupId'],
          label: 'groupName',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
            featureExclude: ['groupByTopLocation'],
            ancestorName: 0,
            periods: 10,
          },
        },
      ],
    },
  },

  lineDetailedByMonthAndGeo: {
    title: 'Satisfacción General',
    // [Column, Row]
    position: [0, 0],
    size: [4, 3],
    config: {
      id: 'line-detailed-by-month-and-geo',
      type: 'lineDetailed',
      //fillMode: 'gradient',
      fillMode: '',
      colors: pieColors,
      showValues: false,
      //percentageScale: false,
      shadow: false,
      mapper: lineDetailedMonthAndGeoMapper,
      indicators: [
        {
          indicator: 'satisfaction',
          grouped: 'formattedMonth,geoLocation',
          keyExtract: ['group_0', 'group_1', 'groupId_0', 'value', 'groupName'],
          label: 'group',
          value: 'value',
          sort: { group_0: 'asc' },
          singleton: true,
          extras: {
            periods: 6,
          },
        },
      ],
    },
  },
}

const normalizeHistoricData = (data: any, radialIndicators: any) => {

  return Object.keys(data)
    .filter((key: any) => key.includes('-ld'))
    .reduce((acc: any, curr: any) => {
      const indicator = curr.replace('-ld', '')
      const currentData = data[curr]
      const historicData = data[indicator + '-hist']
      //console.log(historicData)

      const historicValue = historicData?.[0]?.value
      const currentValue = historicData?.[1]?.value

      //console.log(`calculando historic data ${indicator} - values: hist: ${historicValue} curr: ${currentValue}`)

      return {
        ...acc,
        [curr]: currentData.map((item: any) => ({
          ...item,
          valueNorm: item['value_norm'],
          indicatorValue: currentValue,
          trend: currentValue > historicValue ? 'up' : 'down'
        }))
      }
    }, {})

  /*
  
  const indData = data[radialIndicators?.[0] + '-ld']
  const biggestGroup = indData?.[indData.length - 1]?.['group_0']
  
  return Object.keys(data)
    .filter((item: any) => item.includes('-ld'))
    .reduce((acc: any, curr: any) => {
      const currentData = data[curr]

      const historicValue = currentData
        .find((histItem: any) => histItem['group_0'] !== biggestGroup)
        .reduce((acc: any, curr: any) => curr.value, 0).value

      return {
        ...acc,
        [curr]: currentData
          .filter((item: any) => item['group_0'] === biggestGroup)
          .map((item: any) => {
            const historicValue = currentData
              .find((histItem: any) => histItem['group_0'] !== biggestGroup
                && item['group_1'] === histItem['group_1'])?.value
            const currentValue = item.value
            console.log(`historic value ${historicValue} for ${item['group_1']} current ${currentValue}`)
            const trend = currentValue > historicValue ? 'up' : 'down'

            return {
              id: item.id,
              groupName: item.groupName,
              label: item['group_1'],
              group: item['group_1'],
              value: currentValue,
              valueNorm: item['value_norm'],
              trend: trend
            }
          })
      }
    }, {})*/
}

const truncValue = (value: number, precision: number) => {
  //console.log(`normalizing value ${value} with precision ${precision}`)
  const n = Math.pow(10, precision)
  return Math.trunc(value * n) / n
}

const radialMapperByIndicator = (data: any, extra: any) => {
  console.log('render oxxo lined detailed mapper by indicator')
  console.log(data)
  console.log(extra)

  const radialIndicators = extra?.config?.radialIndicators ?? []
  const normalizedData = normalizeHistoricData(data, radialIndicators)

  const useNormalizedRadial = extra?.config?.useNormalizedRadial ?? true

  //console.log(normalizedData)

  const mainIndicatorKey =
    extra?.config?.mainIndicatorKey ??
    extra?.config?.mainIndicator + '-by-month'
  //console.log(radialIndicators)

  const indicatorsNames = extra?.config?.indicatorsNames

  const radialKeys =
    radialIndicators !== undefined &&
      radialIndicators[0] !== undefined &&
      normalizedData !== undefined &&
      normalizedData[radialIndicators[0] + '-ld'] !== undefined
      ? normalizedData[radialIndicators[0] + '-ld'].map((item: any) => item.group ?? item['group_1'])
      : []
  //console.log(radialKeys)

  const radialData = Object.keys(indicatorsNames)
    .map((indicator: any, indIdx: number) => {
      const itemData = normalizedData[indicator + '-ld']

      var values = {}
      if (itemData !== undefined) {
        values = itemData.reduce((acc: any, curr: any, idx: any) => {
          const geo = curr.label !== undefined ? curr.label : curr.group

          const normValue = useNormalizedRadial
            ? (curr.valueNorm ?? curr['value_norm'])
            : curr.value

          //Iterar sobre todos los valores
          //truncar a la precision elegida y sumarle a cada valor un valor mas chico que la precision ()

          const normPrecision = 6
          const precision = normPrecision - 1
          const adder = 0.01 / Math.pow(10, precision)

          const index = (idx + 1) * (indIdx + 1)
          const currentValue = truncValue(curr.value, normPrecision) + adder * index
          const normalizedValue = truncValue(normValue, normPrecision) + adder * index

          //console.log(`normalized value ${currentValue} origin ${curr.value} for ${geo}`)

          return {
            ...acc,
            [geo]: normalizedValue,
            [`${geo}_norm`]: currentValue,
            indicatorValue: curr.indicatorValue,
            trend: curr.trend
          }
        }, {})
      } else if (radialKeys !== undefined) {
        values = radialKeys.reduce((acc: any, curr: any) => {
          return {
            ...acc,
            [curr]: 101,
            [`${curr}_norm`]: undefined,
            indicatorValue: undefined,
            trend: 'up'
          }
        }, {})
      }

      return {
        indicator: indicatorsNames[indicator],
        ...values
      }
    })

  return {
    ...lineDetailedMonthAndGeoMapper(data[mainIndicatorKey]),
    metrics: [],
    radials: {
      keys: radialKeys,
      data: radialData,
      //maxValue: 100,
      //minValue: 90,
      useNormalization: true,
      indicatorsNames: {
        ...normalizedData[radialIndicators[0] + '-ld']
          .reduce((acc: any, curr: any) => ({
            ...acc,
            [curr.id]: curr.label ?? curr.group
          }), {})
      }
    },
  }
}

const radialMapperByGeo = (data: any, extra: any) => {
  //console.log('render oxxo lined detailed mapper by geo')
  //console.log(data)
  const indicatorsNames = extra?.config?.indicatorsNames ?? {}
  const radialIndicators = extra?.config?.radialIndicators ?? []

  const normalizedData = normalizeHistoricData(data, radialIndicators)

  //console.log(radialIndicators)

  const radialKeys =
    radialIndicators !== undefined &&
      radialIndicators[0] !== undefined &&
      normalizedData !== undefined &&
      normalizedData[radialIndicators[0] + '-ld'] !== undefined
      ? normalizedData[radialIndicators[0] + '-ld'].map((item: any) => item.group)
      : []
  //console.log(radialKeys)

  const radialData = radialKeys.map((geo: any) => {
    const values = radialIndicators.reduce((acc: any, curr: any, index: any) => {
      const current = indicatorsNames[curr]
      const valueCurrent = normalizedData[curr + '-ld']
        .find((item: any) => item.group === geo)?.value
      const valueNorm = normalizedData[curr + '-ld']
        .find((item: any) => item.group === geo)?.value

      return {
        ...acc,
        [current]: valueNorm,
        [`${current}_norm`]: valueCurrent,
      }
    }, {})

    return {
      indicator: geo,
      ...values,
      trend: 'up',
    }
  })

  //console.log(radialData)
  const mainIndicatorKey =
    extra?.config?.mainIndicatorKey ??
    extra?.config?.mainIndicator + '-by-month'

  return {
    ...lineDetailedMonthAndGeoMapper(data[mainIndicatorKey]),
    metrics: [],
    radials: {
      keys: radialIndicators,
      indicatorsNames: indicatorsNames,
      data: radialData,
      useNormalization: true,
      //maxValue: 100,
      //minValue: 90,
    },
  }
}

export const getLineDetailedRadar = (props: {
  title: string
  mainIndicator: string
  radialIndicators?: string[]
  radialType?: 'byGeo' | 'byIndicator'
  extraConfigs?: { [key: string]: any }
  extras?: { [key: string]: any }
  criticalMomentInclude?: number[]
  geoLocationInclude?: number[]
  logicalLocationInclude?: number[]
  indicatorsNames: { [key: string]: string }
  position: number[]
  size: number[]
  groupByLevel?: number
  useNormalizedRadial?: boolean
  normalizeBottom?: number
  normalizeTop?: number
}) => {
  const {
    mainIndicator,
    radialIndicators = [],
    position,
    size,
    radialType = 'byGeo',
    extraConfigs = {},
    extras = {},
    criticalMomentInclude,
    geoLocationInclude,
    logicalLocationInclude,
    indicatorsNames,
    groupByLevel = 1,
    useNormalizedRadial = true,
    normalizeBottom = 50,
    normalizeTop = 100
  } = props

  const mainKey = mainIndicator + '-by-month'

  const mainIndicatorConfig = {
    indicator: mainIndicator,
    key: mainKey,
    grouped: 'formattedMonth,geoLocation',
    keyExtract: ['group_0', 'group_1', 'groupId_0', 'value', 'groupName'],
    label: 'group',
    value: 'value',
    sort: { group_0: 'asc' },
    singleton: true,
    extras: {
      criticalMomentInclude: criticalMomentInclude,
      geographicDistributions: geoLocationInclude,
      logicalDistributions: logicalLocationInclude,
      ...extras,
      periods: 6,
    },
  }

  /*const indicators = [
    'satisfaction', 'service-speed', 'service', 'willingness-to-recommend'
  ]*/

  const radialIndicatorsConfig = radialIndicators.map((ind: string) => ({
    indicator: ind,
    key: ind + '-ld',
    grouped: 'geoLocation',
    //grouped: 'formattedMonth,geoLocation',
    keyExtract: ['value', 'group', 'groupName', 'value_norm'],
    //keyExtract: ['group_0', 'group_1', 'groupId_0', 'value', 'groupName', 'value_norm'],
    label: 'group',
    value: 'value',
    sort: { group: 'asc' },
    singleton: true,
    extras: {
      criticalMomentInclude: criticalMomentInclude,
      geographicDistributions: geoLocationInclude,
      logicalDistributions: logicalLocationInclude,
      ...extras,
      normalize: useNormalizedRadial,
      normalize_a: normalizeBottom,
      normalize_b: normalizeTop,
      periods: 2,
      groupByLevel: groupByLevel,
    },
  }))

  const historicIndicatorsConfig = radialIndicators.map((ind: string) => ({
    indicator: ind,
    key: ind + '-hist',
    grouped: 'formattedMonth',
    keyExtract: ['value', 'group', 'groupName', 'value_norm'],
    label: 'group',
    value: 'value',
    sort: { group: 'asc' },
    singleton: true,
    extras: {
      criticalMomentInclude: criticalMomentInclude,
      geographicDistributions: geoLocationInclude,
      logicalDistributions: logicalLocationInclude,
      ...extras,
      periods: 2,
      groupByLevel: groupByLevel,
    },
  }))

  return {
    ...widgetsLine.lineDetailedByMonthAndGeo,
    //@ts-ignore
    position: position,
    size: size,
    config: {
      ...widgetsLine.lineDetailedByMonthAndGeo.config,
      ...extraConfigs,
      radialIndicators: radialIndicators,
      mainIndicator: mainIndicator,
      mainIndicatorKey: mainKey,
      useNormalizedRadial: useNormalizedRadial,
      indicatorsNames: indicatorsNames,
      mapper: radialType === 'byGeo' ? radialMapperByGeo : radialMapperByIndicator,
      indicators: [
        mainIndicatorConfig,
        ...radialIndicatorsConfig,
        ...historicIndicatorsConfig
      ],
    },
  }
}
